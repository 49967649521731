body {
    font-size: inherit;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divider {
    display: none;
}

.selectorContainer {
    --inv-grid-gutter-x: 0;
    margin: 1.6em 0;
}
.buttonRow {
    margin-top: 1.6em;
}

.divider {
    width: calc(100% + 3em);
    margin-left: 0.2em;
}

@media (min-width: 576px) {
    .container {
        --inv-text-l-font-size: 2.4em;
        --inv-text-xxs-font-size: 1em;
        width: 100%;
    }
    .selectorContainer {
        margin: 3.2em 0;
    }
    body {
        font-size: 13px;
    }
    .buttonRow {
        margin: 1.2em 0 0.6em;
        width: 100%;
    }
    .buttonCol {
        display: flex;
        justify-content: flex-end;
        --inv-grid-gutter-x: 0;
    }
    .divider {
        display: flex;
        width: calc(100% + 6.4em);
        justify-content: flex-end;
    }
}

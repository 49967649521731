.withAndWithoutInveertContainer {
    --inv-grid-gutter-x: 0;
    --inv-grid-gutter-y: 1.6em;
}

.vsCol {
    margin: 0;
}

.vsContainer {
    display: flex;
    margin: 1.6em auto;
    align-items: center;
    justify-content: center;
    background: var(--color-secondary-100);
    border-radius: 50%;
    width: 2em;
    height: 2em;
    padding: 0.5em;
}

@media (min-width: 576px) {
    .vsCol {
        margin: 0 2.8em;
    }
    .vsContainer {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        background: var(--color-secondary-100);
        border-radius: 50%;
        width: 2.2em;
        height: 2.2em;
        padding: 0.6em;
        --inv-text-l-font-size: 1.74em;
    }

    .withAndWithoutInveertContainer {
        --inv-grid-gutter-y: 0;
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.2em;
        --inv-text-m-font-size: 2em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

body {
    font-size: inherit;
}

.marginTopText {
    --inv-grid-gutter-y: 0.8em;
}

.marginTopSubText {
    --inv-grid-gutter-y: 0.4em;
}

.marginTopFirstQuestionRow {
    margin-top: 1.725em;
}

.marginBottomQ1And2 {
    margin-bottom: 1.6665em;
}

.marginBottomQ3 {
    margin-bottom: 1.6665em;
}

.marginTopQ3 {
    margin-top: 2.55em;
}

.marginBottomQ4 {
    margin-bottom: 1.6665em;
    text-align: center;
}
.marginTopSelectorQ1 {
    --inv-grid-gutter-y: 0.8em;
}
.marginTopInput {
    --inv-grid-gutter-y: 1.6em;
}
.marginTopSlider {
    margin-top: 1.2em;
}
.marginTopElementsQ4 {
    margin-top: 1.6em;
}
.marginTopElementAdviceQ4 {
    margin-top: 1.6em;
}
.marginTopElementsMoneyQ3 {
    margin: 0;
}
.paddingSelector {
    padding: 0 0.8em;
}
.marginTopElementsQ3 {
    margin-top: 1.6em;
    text-align: center;
}
.goalCostTitle {
    text-align: center;
}

.goalCostSecondTitle {
    margin-top: 1.6em;
}
.goalCostFirstSubtitle {
    margin-top:1.2em;
    --inv-text-xs-font-size: 0.8em;
    --inv-text-xs-font-weight: 400;
}
.goalCostSecondSubtitle {
    margin-bottom: 1.8em;
}

.goalCostChart {
    margin-bottom: 1.6em;
}
.goalCostChartValue {
    --inv-grid-gutter-y: 2.4em;
}

.titleOfMoneyQ3Container{
    text-align: center;
}

.sliderLeftContainer{
    margin-bottom: 0.4em !important;
    padding: 1.02em 0.5em;
    background: var(--text-color-30);
    border-radius: 0.4em 0 0 0.4em;
    border-right: 0.1em solid white;
}

.adviceContainer{
    margin: 1.6em 0 0 0;
    padding: 0 0.4em;
}

.sliderBubble{
    border: 0.1em solid var(--text-color-10);
    border-radius: 5em;
    padding: 0.3em 0.8em;
    position: relative;
    top: 0.3em;
    display: none;
}

.sliderBubbleMobileRow{
    justify-content: center;
    margin-top: 2em;
}

@media (min-width: 576px) {
    .sliderBubbleMobileRow{
        display: none;
    }
    .sliderBubble{
        display: inherit;
    }

    body {
        font-size: 13px;
    }

    .sliderLeftContainer{
        padding: 0.5em;
    }

    .marginTopText {
        --inv-grid-gutter-y: 2em;
    }

    .marginBottomTextFirstQuestion {
        margin-bottom: 2em;
    }

    .marginTopSubText {
        --inv-grid-gutter-y: 0.8em;
    }
    .marginTopFirstQuestionRow {
        margin-top: 2em;
    }
    .marginBottomQ1And2 {
        margin-bottom: 4em;
    }

    .marginTopQ3 {
        margin-top: 2em;
    }

    .titleOfMoneyQ3Container{
        text-align: initial;
    }

    .titleOfMoneyQ3 {
        --inv-text-s-font-size: 1.2em;
    }

    .marginTopElementsMoneyQ3 {
        margin: 1.5em 0 0 !important;
    }

    .marginBottomQ3 {
        margin-bottom: 4.2em;
    }
    .marginTopSelectorQ1 {
        --inv-grid-gutter-y: 0;
    }
    .marginTopSlider {
        margin-top: 7.5em;
    }
    .titleOfMoneyQ4 {
        --inv-text-s-font-size: 1.2em;
    }
    .marginTopElementsQ4 {
        margin-top: 4em;
    }
    .textOfQuantitySlider {
        --inv-text-s-font-size: 1.2em;
    }
    .marginBottomQ4 {
        margin-bottom: 2em;
        --inv-text-l-font-size: 1.6em;
        --inv-text-l-color: var(--text-color-50);
        --inv-text-l-font-weight: 600;
        text-align: initial;
    }
    .marginTopElementAdviceQ4 {
        margin-top: 2em;
    }
    .marginTopElementsQ3 {
        margin-top: 0.8em;
        --inv-text-l-font-size: 1.6em;
        text-align: initial;
    }
    .paddingSelector {
        padding: 0;
    }
    .goalCostTitle {
        --inv-text-m-font-size: 1.8em;
        text-align: initial;
    }
    .goalCostSecondTitle {
        margin-top: 0.8em;
        margin-bottom: 0.8em;
        --inv-text-m-font-size: 2.4em;
    }
    .goalCostFirstSubtitle {
        --inv-text-xs-font-size: 1.6em;
        margin-top: 0.8em;
    }
    .goalCostSecondSubtitle {
        margin-bottom: 1.6em;
    }

    .goalCostChart {
        margin-bottom: 2.8em;
    }
    .goalCostChartValue {
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xs-font-size: 1.8em;
        --inv-grid-gutter-y: 2.9em;
    }
    .goalCostChartValueUnit {
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1em;
    }
    .container {
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
    .adviceContainer{
        margin: 2em 0;
        padding: initial;
    }
}

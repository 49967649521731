.container {
    font-size: inherit;
    max-width: 1290px;
    margin: 1.2em 0 0;
    background: #fff;
    border-radius: 0;
    width: 100%;
    padding: 1.6em 0.8em;
}

.img-container {
    width: 100%;
}
.subtitle1 {
    margin: 0.8em 0 0;
}

.withAndWithoutInveertContainer {
    margin: 2.4em 0 0;
    --inv-grid-gutter-x: 0;
    --inv-grid-gutter-y: 1.6em;
}

.title2 {
    margin: 1.6em 0 0;
}

.chartInfoContainer {
    margin: 1.2em 0 0.6em;
}
.chartInfoBorderedContainer {
    border: 0.025em solid;
    border-radius: 0.4em;
    padding: 0.4em;
    --inv-grid-gutter-x: 0.8em;
}

.chart {
    margin: 0 0 2.5em;
}

.divider {
    display: none;
}
.buttonsContainer {
    justify-content: center;
}

.titleContainer{
    justify-content: center;
    margin-bottom: 0.8em;
}

.borderedContainerButton {
   --inv-grid-gutter-x: 0;
    margin-top: 2.4em;
}

.borderedContainer {
    --inv-grid-gutter-x: 0;
    align-items: center;
    margin-top: 1.6em;
    padding: 1.6em 1.2em;
}

.adviceContainer{
    padding: 0 0.8em;
    justify-content: center;
    --inv-text-s-font-size: 1em;
}

.disclaimerContainer{
    display: flex;
    justify-content: center;
    margin: 2.5em 0 2.5em;
}

@media (min-width: 576px) {
    .disclaimerContainer{
        margin: -5em 0 5em;
    }
    .adviceContainer{
        padding: 0;
        --inv-text-s-font-size: inherit;
    }
    .titleContainer{
        justify-content: center;
        margin-bottom: 2em;
    }
    .img-container {
        width: 41%;
    }
    .subtitle1 {
        margin: 2em 0 0;
    }
    .withAndWithoutInveertContainer {
        margin: 2em 0 0;
        --inv-grid-gutter-y: 0;
    }
    .title2 {
        margin: 2.8em 0 0;
    }
    .chartInfoContainer {
        margin: 2.5em 0 4.6em;
    }

    .chartInfoBorderedContainer {
        border: none;
        padding: 0;
        --inv-grid-gutter-x: 1.2em;
        --inv-text-l-font-size: 1.8em;
        --inv-text-s-font-size: 1.8em;
    }
    .chartInfoDivider {
        height: 4.1em;
    }
    .chart {
        margin: 0 0 4.8em;
    }
    .divider {
        display: flex;
        --inv-grid-gutter-x: 0;
    }
    .buttonsContainer {
        justify-content: flex-end;
        margin-top: 1.2em;
    }
    .chartInfoDivider {
        height: 2.5em;
    }
    .borderedContainer {
        padding: 1.2em;
        margin-top: 3.6em;
    }

    .borderedContainerButton {
        display: flex;
        justify-content: flex-end;
        margin-top: initial;
    }
    .container {
        padding: 3.2em 1.2em;
        font-size: inherit;
        max-width: 64em;
        margin: 2em auto 10em;
        border-radius: 1.6em;
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.2em;
        --inv-text-m-font-size: 2em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

.container {
    background: #fff;
    border-radius: 0 0 1.2em 1.2em;
    padding: 1.6em 1.2em;
}

.sectionTextRow > *:last-child {
    margin-top: 0.4em;
    --inv-grid-gutter-x: 0;
}

.apportationsContainer {
    --inv-grid-gutter-x: 0.8em;
    --inv-text-l-font-size: 0.8em;
    justify-content: space-between;
}

.sectionTextRow + * {
    margin-top: 1.6em;
}

* + .sectionTextRow {
    margin-top: 2.4em;
}

.apportationsContainer + * {
    margin-top: 0.8em;
}

.tirContainer {
    margin-top: 0.4em;
    line-height: 0;
    --inv-grid-gutter-x: 0.95em;
    --inv-text-xxs-font-weight: 400;
    align-items: center;
    border-radius: 0.4em;
    box-shadow: 0 0.4em 0.4em 0.05em rgba(170, 186, 202, 0.2);
    padding: 0.4em;
}

.recommendationContainer {
    --inv-grid-gutter-y: 0.8em;
    justify-content: center;
}

.recommendationContainer > *:first-child {
    margin-top: 0;
}

.totalCapitaApportationContainer {
    display: flex;
    flex-direction: column;
    --inv-text-l-font-size: 0.8em;
    line-height: 0;
    --inv-grid-gutter-x: 0.4em;
    --inv-grid-gutter-y: 0.4em;
}

.finalChartLabelContainer {
    justify-content: space-between;
    align-items: center;
    --inv-grid-gutter-x: 0.4em;
    margin-bottom: 1.6em;
}


.buttonLink {
    font-family: var(--inv-font-family);
    background: none !important;
    border: none;
    padding: 0 !important;
    font-weight: 500;
    /*input has OS specific font-family*/
    cursor: pointer;
    color: var(--color-primary-95);
    display: flex;
    font-size: 0.8em;
    align-items: center;
    margin-top: 0.5em;
}

* + .sectionTextRow {
    margin-top: 2.8em;
}

.monthlySavingGoalsContainer {
    justify-content: space-between;
    --inv-grid-gutter-y:0.8em ;
}

.tirLabel{
    margin-bottom: -0.8em;
    --inv-text-xxs-font-size: 0.6em;
    --inv-text-xxs-color: var(--text-color-50);
}

@media (min-width: 576px) {
    .tirLabel{
        margin-bottom: 0.2em;
        --inv-text-xxs-font-size: 0.8em;
    }
    .buttonLink {
        /*input has OS specific font-family*/
        text-decoration: underline;
        font-size: 1.4em;
        margin-top: 0.5em;
    }
    .sectionTextRow {
        --inv-text-m-font-size: 2.4em;
    }
    .sectionTextRow > *:not(:first-child) {
        margin-top: 0.8em;
    }

    .tirContainer {
        margin-top: initial;
        --inv-grid-gutter-x: 0;
        padding: 0.4em 0.8em;
        --inv-text-xxs-font-size: 0.8em;
        margin-bottom: 0.4em;
    }
    .tirContainer > *:first-child {
        margin-right: 1.6em;
    }

    .container {
        padding: 4em 3.875em 4.25em;
    }

    .sectionTextRow + * {
        margin-top: 3.2em;
    }
    .apportationsContainer {
        --inv-text-l-font-size: inherit;
        --inv-grid-gutter-x: 0;
    }
    .apportationsContainer + * {
        margin-top: 1.5em;
    }
    * + .sectionTextRow {
        margin-top: 4.6em;
    }
    .recommendationContainer {
        --inv-grid-gutter-y: 1.6em;
    }

    .performanceChartContainer {
        margin-bottom: 1.2em;
        justify-content: center;
    }

    .totalCapitaApportationContainer {
        flex-direction: inherit;
        --inv-text-l-font-size: 1.8em;
        --inv-grid-gutter-x: 1.6em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xs-font-size: 1em;
    }
    .finalChartLabelContainer {
        align-items: center;
        margin-bottom: 3.2em;
    }
    .initialInvestmentOfObjectivesContainer {
        --inv-grid-gutter-x: 0;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 1.8em;
        --inv-text-xs-font-size: 1em;
        line-height: 0;
    }
    .monthlySavingGoalsContainer {
        --inv-grid-gutter-y:inherit ;
        justify-content: center;
        --inv-grid-gutter-x: 3.2em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 1.8em;
        --inv-text-xs-font-size: 1em;
    }
    .monthlySavingGoalsHelper{
        --inv-text-xxs-font-size: 0.7em;
    }
    .monthlySavingAddGoal{
        --inv-text-l-font-size: 0.8em;
        --inv-text-l-color: var(--color-primary-100);
    }
}

.main {
    position: relative;
}

.titleContainer{
    --inv-text-l-font-weight: 700;
    --inv-text-m-font-weight: 300;
    --inv-text-m-color: var(--text-color-50);
}

.titleContainer > inv-grid-row{
    justify-content: center;
}

.titleContainer > *:not(:first-child){
    margin-top: 0.8em;
}

.radioContainer{
    --inv-text-xxs-color: var(--text-color-50);
    --inv-text-xxs-font-weight: 300;
}

.radioContainer > inv-grid-row{
    justify-content: center;
}

@media (min-width: 576px) {
    .main {
        --inv-text-m-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-l-font-size: 2.4em;
        padding: 1em 3.75em 5.2em;
    }
    .main > *:not(:first-child):not(:last-child) {
        margin-top: 3.25em;
    }
    .main > *:last-child {
        margin-top: 4.2em;
    }
    form > input:not(:first-child) {
        margin-top: 0.9em;
    }
}
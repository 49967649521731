.main > inv-grid-row{
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 576px) {
    .dividerContainer{
        position: absolute;
        left: 0;
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;
    }
    .buttonContainer{
        padding: 1.2em 0;
        justify-content: space-between;
    }

    .buttonContainer > inv-grid-col:first-child{
        display: flex;
        justify-content: flex-start;
    }

    .buttonContainer > inv-grid-col:last-child{
        display: flex;
        justify-content: flex-end;
    }
}
.marginTopQ1Element1 {
    margin-top: 3.75em;
    --inv-grid-gutter-y: 0.8em;
}

.marginTopQ1Element2 {
    margin-top: 0.8em;
    --inv-grid-gutter-x: 0;
}

.marginTopQ1Element3 {
    margin-top: 1.6em;
    --inv-grid-gutter-x: 0;
}

.marginBottomQ1Element3 {
    margin-bottom: 1.6em;
}

.marginTopQ2Element1 {
    margin-top: 7em;
}

.marginTopQ2Element2 {
    margin-top: 3em;
}

.marginQ2Element3 {
    margin: 1.6em 0;
}

.marginQ3Element1 {
    margin: 3.75em 0 1.6em;
}

@media (min-width: 576px) {
    .marginTopQ1Element1 {
        margin-top: 2.125em;
        --inv-grid-gutter-y: 0;
    }

    .marginTopQ1Element2 {
        margin-top: 2.075em;
    }

    .marginTopQ1Element3 {
        margin-top: 2em;
    }

    .marginBottomQ1Element3 {
        margin-bottom: 2em;
    }

    .marginTopQ2Element1 {
        margin-top: 8em;
    }

    .marginTopQ2Element2 {
        margin-top: 3.2em;
    }

    .marginQ2Element3 {
        margin: 5.2em 0 4em;
    }

    .marginQ3Element1 {
        margin: 4em 0 4em;
    }

    .container {
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

.container {
    background: #fff;
    max-width: 53.6em;
    margin: 3.2em auto 6.4em;
    padding: 2.4em 3.7em;
    border-radius: 1.6em;
}

.container > *:nth-child(3) {
    margin-top: 0.8em;
}

.container > *:last-child {
    margin-top: 2em;
}

.social > * {
    margin-top: 1em;
}

.apportationsContainer {
    --inv-grid-gutter-x: 0.8em;
    justify-content: space-between;
}
.apportationsContainer + * {
    margin-top: 0.8em;
}

.divider{
    display: none;
}

.totalContainer{
    display: flex;
    justify-content: center;
    --inv-text-l-font-size: 1.2em;
    margin-bottom: 1.25em;
}

.helperText {
    --inv-text-xs-font-size: 0.6em;
    --inv-text-xs-font-weight: 600;
    --inv-text-xs-color: #ff5f29;
    --inv-text-xxs-font-size: 0.6em;
    --inv-text-xxs-font-weight: 600;
    --inv-text-xxs-color: #ff5f29;
}

.barWithTextContainer{
    justify-content: center;
    --inv-grid-gutter-x: 0;
}

.main{
    justify-content: center;
}

@media (min-width: 576px) {

    .barWithTextContainer{
        justify-content: initial;
        --inv-grid-gutter-x: inherit;
    }
    .totalContainer{
        display: initial;
        justify-content: initial;
        --inv-text-l-font-size: inherit;
        margin-bottom: 0;
    }
    .divider{
        display: flex;
        height: 5.5em;
    }
    .textsOfBarContainer {
        --inv-text-xs-font-size: 1em;
        --inv-text-l-font-size: 1.6em;
        --inv-grid-gutter-x: 10em;
    }
    .barContainer {
        --inv-text-xs-font-size: 0.8em;
        --inv-text-l-font-size: 1.6em;
        --inv-grid-gutter-x: 0;
    }
    .containerOfInitialAndMonthly {
        --inv-grid-gutter-x: 2em;
    }
    .stepTitleContainer {
        margin-bottom: 0.8em;
    }
    .apportationsContainer + * {
        margin-top: 0.4em;
    }
    .helperText {
        --inv-text-xs-font-size: 0.8em;
        --inv-text-xs-font-weight: 600;
        --inv-text-xs-color: #ff5f29;
        --inv-text-xxs-font-size: 0.6em;
        --inv-text-xxs-font-weight: 600;
        --inv-text-xxs-color: #ff5f29;
    }
    .main{
        --inv-grid-gutter-x: 3.2em;
        align-items: center;
    }
}
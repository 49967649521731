.marginTopText {
    --inv-grid-gutter-y: 0.8em;
}

.marginTopSubText {
    --inv-grid-gutter-y: 0.4em;
}

.marginTopFirstQuestionRow {
    margin-top: 3.75em;
}

.marginTopQ2Element1 {
    margin-top: 3.325em;
}

.marginTopQ2Element2 {
    margin-top: 1.6em;
}

.marginQ2LastElement {
    margin: 4.65em 0 2.9em;
}

.marginBottomQ3 {
    margin-bottom: 1.6em;
}

.marginTopAdviceQ1 {
    --inv-grid-gutter-x: 0;
    margin-top: 1.6em;
}

.marginBottomAdviceQ1 {
    margin-bottom: 1.6em;
}

.marginBottomQ1And2 {
    margin-bottom: 1.6665em;
}

.marginBottomQ3 {
    margin-bottom: 1.6665em;
}

.marginTopQ3Element1 {
    margin-top: 0.4em;
}
.marginQ3LastElement {
    margin: 1.6em 0;
}

.marginBottomQ4 {
    margin-bottom: 1.6665em;
}
.marginTopSelectorQ1 {
    --inv-grid-gutter-y: 0.8em;
}
.marginTopInput {
    --inv-grid-gutter-y: 1.6em;
}
.marginTopSlider {
    margin-top: 4.2em;
}
.marginTopElementsQ4 {
    margin-top: 1.6em;
}
.marginTopElementAdviceQ4 {
    margin-top: 1.6em;
}
.marginTopElementsMoneyQ3 {
    margin: 0;
}
.paddingSelector {
    padding: 0 0.8em;
}
.marginTopElementsQ3 {
    margin-top: 1.6em;
}

@media (min-width: 576px) {
    .marginTopText {
        --inv-grid-gutter-y: 2em;
    }
    .marginTopSubText {
        --inv-grid-gutter-y: 0.8em;
    }
    .marginTopFirstQuestionRow {
        margin-top: 5.2em;
    }

    .marginTopAdviceQ1 {
        margin-top: 4em;
    }

    .marginBottomAdviceQ1 {
        margin-bottom: 5.2em;
    }

    .marginTopQ2Element1 {
        margin-top: 4em;
    }

    .marginTopQ2Element2 {
        margin-top: 3.2em;
    }

    .marginQ2LastElement {
        margin: 4.225em 0 5.2em;
    }

    .marginTopQ3Element1 {
        margin-top: 0.8em;
        --inv-text-xs-font-size: 1em;
    }
    .marginQ3LastElement {
        margin: 3.2em 0 4em;
    }

    .marginBottomQ1And2 {
        margin-bottom: 4em;
    }

    .marginTopQ3 {
        margin-top: 2em;
    }

    .marginTopElementsMoneyQ3 {
        margin: 1.5em 0 0 !important;
    }

    .marginBottomQ3 {
        margin-bottom: 4.2em;
    }

    .marginBottomQ4 {
        margin-bottom: 2em;
    }
    .marginTopSelectorQ1 {
        --inv-grid-gutter-y: 0;
    }
    .marginTopSlider {
        margin-top: 5.5em;
    }
    .marginTopElementsQ4 {
        margin-top: 2em;
    }
    .marginTopElementAdviceQ4 {
        margin-top: 4em;
    }
    .marginTopElementsQ3 {
        margin-top: 0.8em;
    }
    .paddingSelector {
        padding: 0;
    }
    .container {
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

.container {
    font-size: inherit;
    max-width: 72em;
    margin: 5em auto 10em;
    background: #fff;
    border-radius: 32px;
    width: 100%;
    padding: 5.2em 0 1.2em;
    --inv-form-label-margin-bottom: 0.4em;
}

.img-container {
    width: 100%;
}

@media (max-width: 2000px) {
    .container {
        font-size: inherit;
        margin: 2em auto 10em;
    }
    .img-container {
        width: 41%;
    }
}

.container {
    font-size: inherit;
    max-width: 1290px;
    margin: 1.2em 0 0;
    background: #fff;
    border-radius: 0;
    width: 100%;
    padding: 2.4em 0.8em 1.6em;
}

.img-container {
    width: 100%;
}

.secondTitle {
    margin: 1.8em 0 0;
    justify-content: center;
    display: flex;
}

.chartsContainer {
    margin: 1.6em 0;
    align-items: flex-end;
    justify-content: center;
    --inv-grid-gutter-x: 0.5em;
}

.chartElementTitle {
    justify-content: center;
    margin: 0 0 0;
}
.lastTitle {
    margin: 1.6em 0;
    justify-content: center;
}
.divider {
    display: none;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    --inv-grid-gutter-x: 0;
}

.buttonElement1 {
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 0.8em;
}
.buttonElement2 {
    display: flex;
    justify-content: center;
    order: 1;
}
.borderedContainer{


}
.savingContainer{
    width: 3.43em;
    height: 3.21em;
    background-color: var(--text-color-30);
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;
    margin-bottom: 0;
}

.profitContainer{
    width: 3.43em;
    height: 5.12em;
    background-color: var(--color-secondary-100);
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;
    margin-bottom: 0;
}

.savingTotalContainer{
    width: 3.43em;
    height: 3.21em;
    background-color: var(--text-color-30);
    margin-bottom: 0;
}

.profitTotalContainer{
    width: 3.43em;
    height: 5.12em;
    background-color: var(--color-secondary-100);
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em
}

.contentContainer{
    padding: 0 0.8em;
    justify-content: center;
}

@media (min-width: 576px) {
    .contentContainer{
        padding: 0;
    }

    .savingTotalContainer{
        width: 6.9em;
        height: 6.45em;
        margin-bottom: 0.6em;
    }

    .profitTotalContainer{
        width: 6.9em;
        height: 10.3em;
        border-top-left-radius: 1.2em;
        border-top-right-radius: 1.2em
    }
    .savingContainer{
        width: 6.9em;
        height: 6.45em;
        margin-bottom: 0.6em;
    }
    .profitContainer{
        width: 6.9em;
        height: 10.3em;
        margin-bottom: 0.6em
    }
    .buttonsContainer {
        display: flex;
        justify-content: space-between;
        padding: 1.2em 0 0;
        --inv-grid-gutter-x: 0;
    }
    .buttonElement1 {
        display: flex;
        justify-content: flex-start;
        order: 1;
        margin-top: initial;
    }
    .buttonElement2 {
        display: flex;
        justify-content: flex-end;
        order: 2;
    }
    .secondTitle {
        margin: 0;
        --inv-text-xs-font-size:1.8em;
        --inv-text-xs-font-weight: 600;
    }
    .chartsContainer {
        margin: 1em 0 0;
        --inv-grid-gutter-x: 1.2em;
    }
    .noCNMVContainer{
        margin: 0 0 5.3em;
    }
    .chartElementTitle {
        margin: 0 0 0;
    }
    .lastTitle {
        margin: 3.8em 0 2.2em;
    }
    .divider {
        display: flex;
    }
    .container {
        padding: 2.4em 1.25em 1.2em;
        font-size: inherit;
        max-width: 64em;
        margin: 2em auto;
        border-radius: 1.6em;
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.8em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
    .img-container {
        width: 41%;
    }
    .borderedContainer {
        border: 0.05em solid var(--text-color-30);
        padding: 1.6em;
        border-radius: 0.4em;
        margin-top: 2em;
    }
}

.apportationsPercentageContainer {
    justify-content: space-between;
    --inv-grid-gutter-x: 0;
    margin-top: 0.5em;
}
@media (min-width: 576px) {
    .apportationsPercentageContainer {
        margin-top: 0.4em;
    }
}

@media (min-width: 576px) {
    .pageContainer {
        --inv-text-m-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-l-font-size: 2.4em;
        margin-top: 5.5em;
    }
}

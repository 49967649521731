.main {
    position: relative;
}

.titleContainer{
    --inv-text-l-font-weight: 700;
    --inv-text-m-font-weight: 300;
    --inv-text-m-color: var(--text-color-50);
}

.titleContainer inv-grid-row{
    justify-content: center;
    text-align: center;
}

.titleContainer > .titleFirstLine{
    --inv-text-l-color: var(--text-color-30);
}

.titleContainer > .titleSecondLine{
    --inv-text-l-color: var(--color-tertiary);
}

.titleContainer > .titleThirdLine{
    --inv-text-xxs-color: var(--text-color-50);
    --inv-text-xxs-font-weight: 300;
    margin-top: 0.8em;
}

.pendingContainer{
    --inv-text-xxs-color: var(--text-color-50);
    --inv-text-xxs-font-weight: 600;
    --inv-text-l-color: var(--text-color-50);
    --inv-text-l-font-weight: 600;
}

.pendingContainer > inv-grid-row{
    justify-content: center;
    text-align: center;
}

.buttonContainer{
    align-items: center;
}

@media (min-width: 576px) {
    .main {
        --inv-text-m-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-l-font-size: 2.4em;
        padding: 1em 1.6em 0;
    }
    .main > *:not(:first-child) {
        margin-top: 2em;
    }
    .resultsContainer{
        padding: 2em 0;
    }
    .dividerContainer{
        position: absolute;
        left: 0;
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;
    }
    .buttonContainer{
        padding: 1.2em 0;
        justify-content: space-between;
    }
}
.alertContainer{
--inv-grid-gutter-x: 3.2em;
background: #fff;
width: max-content;
padding-left: calc(var(--inv-grid-gutter-x,1.5em) * 0.5);
padding-right: calc(var(--inv-grid-gutter-x,1.5em) * 0.5);
}

.alert{
    background: var(--plan-alert-background-color,#e22e590d);
    background-clip: padding-box;
    border: 0.1em solid var(--plan-alert-border-color,#e22e5933);
    box-shadow: 0px 0.2em 0.5em 0px rgba(0, 0, 0, 0.15);
    justify-content: center;
    padding: 0.8em;
    border-radius: 0.4em;
    align-items: center;
}

.alertButton{
    font-size: 0.7em;
--inv-button-contained-background: var(--plan-alert-button-color,var(--error-color));
--inv-button-contained-hover-background: var(--plan-alert-button-color-hover,#ac183b) ;
}

@media (min-width: 576px) {
    
}
.container {
    display: flex;
    min-width: 25em;
    margin-top: 1em;
}
@media (min-width: 576px) {
    .container {
        display: block;
        --inv-text-xs-font-size: 0.8em;
        margin-top: inherit;
    }
    .link-active {
        --inv-text-xs-color: rgba(0, 8, 38, 0.80);
        border-bottom: 0.05em solid rgba(0, 8, 38, 0.80)
    }
    .link-active {
    --inv-text-xs-color: rgba(0, 8, 38, 0.80);
    border-bottom: 0.05em solid rgba(0, 8, 38, 0.80)
    }
    .link-active:hover {
    --inv-text-xs-color: var(--color-primary-100);
    border-bottom: 0.05em solid var(--color-primary-100)
    }
}

.marginTopQ1Element1 {
    margin-top: 2.6em;
}

.marginQ1Element2 {
    margin: 1.6em;
    --inv-grid-gutter-x: 0;
}

.marginTopQ2Element1 {
    margin-top: 4.5em;
}

.marginTopQ2Element2 {
    margin-top: 1.6em;
}

.marginBottomQ2Element2 {
    margin-bottom: 1.6em;
}

.marginQ3Element1 {
    margin: 3em 0 1.6em;
    --inv-grid-gutter-y: 0.8em;
}

.marginQ3Element2 {
    margin: 0 0 1.6em;
}
.marginQ4Element1 {
    margin: 7em 0 1.6em;
}

@media (min-width: 576px) {
    .marginTopQ1Element1 {
        margin-top: 4em;
    }

    .marginQ1Element2 {
        margin: 3.2em 0 4em;
    }

    .marginTopQ2Element1 {
        margin-top: 5em;
    }

    .marginTopQ2Element2 {
        margin-top: 2em;
    }

    .marginBottomQ2Element2 {
        --inv-tex-s-font: 1.2em;
        margin-bottom: 5em;
    }

    .marginQ3Element1 {
        margin: 4em 0 2em;
    }

    .marginQ3Element2 {
        margin: 2em 0;
        --inv-text-xs-font-size: 1.6em;
    }

    .marginQ4Element1 {
        margin: 7em 0 2em;
    }

    .container {
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

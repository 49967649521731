.outsideBar{
    display: none;
    height: 5.1em;
    width: 0.1em;
    background-color: var(--text-color-10);
    margin-left: 0.05em;
    margin-right: 0.05em;
    position: absolute;
    z-index: 1;
    bottom: -23;
}

@media (min-width: 576px){
    .outsideBar{
        display: initial;
    }
}
.title {
    margin-bottom: 0.8em;
    display: flex;
    --inv-grid-gutter-x: 0.4em;
    align-items: center;
}

.firstSubtitle {
    margin-bottom: 0.4em;
}
.lastRow {
    --inv-grid-gutter-x: 0;
    margin: 1.4em 0 0;
}
.subtitle {
    margin-bottom: 1.4em;
}

.chartContainer {
    margin-top: 2em;
}

.container{
    --inv-text-l-font-size: 1em;
    --inv-text-m-font-size: 1.2em;
    --inv-text-s-font-size: 0.9em;
    --inv-text-xxs-font-size: 0.65em;
}

.lastRow{
    --inv-text-s-font-size: 1.2em;
}

.firstSubtitle {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .bigQuantity {
        --inv-text-s-font-size: 1em;
    }
    .smallQuantity {
        --inv-text-xs-font-size: 0.7em;
    }
    .lastRow {
        margin: 1.4em 0 0;
    }
    .firstSubtitle {
    }
    .title {
        margin-bottom: 1.4em;
    }
    .container {
        --inv-text-l-font-size: 1.8em;
        --inv-text-s-font-size: 1.2em;
        --inv-text-xs-font-size: 0.8em;
        --inv-text-xxs-font-size: 0.7em;
    }
}

.main{
    border-radius: 0.8em;
    border: 0.1em dashed var(--text-color-30);
    --inv-text-s-color: var(--color-tertiary);
    --inv-text-s-font-weight: 700;
}

.disabled{
    --inv-text-l-color: var(--text-color-40);
    --inv-text-s-color: var(--text-color-40);
    --inv-text-xs-color: var(--text-color-40);
    --inv-text-xxs-color: var(--text-color-40);
}

.main  inv-grid-row{
    justify-content: flex-start;
}


@media (min-width: 576px) {
.main{
    --inv-text-m-font-size: 1.6em;
    --inv-text-xxs-font-size: 1em;
    --inv-text-xs-font-size: 1em;
    --inv-text-s-font-size: 1.6em;
    --inv-text-l-font-size: 2em;
    padding: 1.6em 1.2em;
}

    .main > *:not(:first-child) {
        margin-top: 1.4em;
    }

}
@media (min-width: 576px) {
    .pageContainer {
        max-width: 44em;
        background: #fff;
        margin: 6.15em auto 12.45em;
        width: 100%;
        padding: 2.4em 1.6em;
        border-radius: 1.6em;
        --inv-text-m-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-l-font-size: 2.4em;
    }
    .titleContainer > *:last-child {
        margin-top: 0.8em;
    }
    .listNumber {
        height: 1.6em;
        width: 1.6em;
        border-radius: 50%;
        border: 0.1em solid var(--color-primary-95);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .listNumber > inv-text-xxs {
        --inv-text-xxs-font-size: 0.8em;
    --inv-text-xxs-font-weight: 600;
    --inv-text-xxs-color: var(--color-primary-95);
    }
    .itemList {
        margin: 2.8em auto;
    }
    .itemList > *:not(:last-child) {
        margin-bottom: 2.4em;
    }
}

.closeIconContainer {
    cursor: pointer;
    margin: 1em 1em 0 0;
}

.closeIconContainer{
    cursor: pointer;
    margin: 1em 1em 0 0;
    display: flex;
    justify-content: flex-end;
    top: 0;
}
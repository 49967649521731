.container {
    background: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    border: 0.1em solid var(--text-color-30);
    border-radius: 5.6em;
    padding: 1.05em 1.6em;
    background: #fff;
    box-sizing: border-box;
    --inv-grid-gutter-y: 0;
}

.container:hover {
    border-color: var(--inv-input-radio-focus-border-color);
}

.container.selected {
    border: 0.2em solid var(--inv-input-radio-checked-outline-color);
}

.container.disabled {
    border: 0.1em solid var(--text-color-30);
    background: var(--text-color-10);
    pointer-events: none;
}

.icon-container {
    justify-content: center;
    align-items: center;
    --inv-grid-gutter-x: 1.2em;
}

@media (min-width: 576px) {
    .container {
        padding: 1.6em;
        --inv-text-m-font-size: 1.2em;
        --inv-text-xs-font-size: 0.8em;
    }

    .icon-container {
        --inv-grid-gutter-x: 0;
    }
}

.main{
    border: 0.05em solid var(--text-color-30);
    border-radius: 0.4em;
}

main > .section:last-child{
}

.header{
    align-items: center;
}

.probability{
    background:var(--probability-badge-bg);
    border-radius: 0.4em;
    --inv-text-xxs-font-weight: 600;
    --inv-text-xxs-color: var(--probability-badge-color);
}

.titleWithTypeIcon{
    --inv-text-s-font-weight: 700;
}

.section{
    --inv-text-xs-color: var(--color-primary-95);
    --inv-text-xs-font-weight: 600;
    --inv-text-xxs-color: var(--text-color-50);
}

.sectionSubtitle{
    align-items: center;
}

.sectionTextStructures{
    --inv-text-xs-color: var(--text-color-100);
    align-items: center;
    --inv-grid-gutter-x: 1.2em;
}

.vs{
    --inv-text-xxs-color: var(--accent-color-1);
    --inv-text-xxs-font-weight: 600;
    border-radius: 50em;
    background: var(--text-color-50);
    display: flex;
    justify-content: center;
    align-items: center;
}

.funnelChartTitle{
    --inv-text-xxs-color: var(--text-color-50);
}

@media (min-width: 576px) {
    .main{
        padding: 1.2em 1.2em 3.2em;
    }
    .main > .section:last-child{
        margin: 2em 0 0;
    }
    .header{
        justify-content: space-between;
        margin-bottom: 2.4em;
    }
    .goalIconsContainer{
        height: 2.4em;
        --inv-grid-gutter-x: 0.7em;
    }
    .probability{
        padding: 0.8em;
        --inv-text-xxs-font-size: 0.8em;
    }
    .titleWithTypeIcon{
        align-items: center;
        --inv-grid-gutter-x: 2.2em;
    }
    .section{
        margin: 2em 0;
    }

    .section > *:not(:first-child):not(.funnelChart) {
        padding-left: 0.8em;
        margin-top:1.6em
    }

    .sectionSubtitle{
        --inv-grid-gutter-x: 0.3em;
    }
    .sectionTextStructures{
        --inv-text-xxs-font-size: 0.7em;
        --inv-text-xs-font-size: 0.7em;
        --inv-text-l-font-size: 1.2em;
    }
    .vs{
        --inv-text-xxs-font-size: 0.6em;
        height: 1.2em;
        width: 1.2em;
    }
    .funnelChartTitle{
        --inv-text-xxs-font-size: 0.7em;
    }
    .funnelChart{
        margin-top:-2.7em;
        margin-left: 2em;
    }
}
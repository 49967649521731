.recommendationContainer {
    --inv-grid-gutter-y: 0.8em;
    border: 0.05em solid #8ba1b6;
    border-radius: 0.4em;
    padding: 0 1.2em 1.2em ;
    --inv-grid-gutter-x: 0;
    margin: 2.4em 0;
    width: 40em;
}

.textStructureContainer {
    --inv-text-l-font-size: 0.6em;
    line-height: 0;
}

.recommendationHeaderInfo {
    margin: 0 0 1.1em;
    justify-content: space-between;
    flex-wrap: nowrap;
    --inv-grid-gutter-x: 0.8em;
}

.equityChartContainer > inv-grid-row {
    flex-wrap: nowrap;
}

.descriptionCol{
    max-width: 20em;
}

.equityChartTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 2em;
}

.equityChartRow{
    --inv-grid-gutter-y:0 ;
}
@media (min-width: 576px) {
    .equityChartRow{
        --inv-grid-gutter-y:inherit ;
    }
    .descriptionCol{
        max-width: 100%;
    }

    .equityChartContainer > inv-grid-row {
        flex-wrap: wrap;
    }
    .recommendationContainer {
        padding: 1.2em ;
        --inv-grid-gutter-y: 1.6em;
        width: initial;
        --inv-grid-gutter-x: inherit;
        margin: initial;
    }
    .recommendationHeaderInfo {
        --inv-grid-gutter-x: 0;
        --inv-grid-gutter-y: 0;
        justify-content: initial;
        flex-wrap: wrap;
    }

    .goalIconsContainer {
        --inv-grid-gutter-x: 1.2em;
    }
    .recommendationHeaderInfo > *:not(:first-child) {
        margin-left: 1.6em;
    }
    .textStructureContainer {
        --inv-text-l-font-size: 1.2em;
        --inv-text-xxs-font-size: 0.7em;
        --inv-text-xs-font-size: 0.7em;
        line-height: 0;
    }
    .textStructurePercentageContainer {
        --inv-text-l-font-size: 1.2em;
        --inv-text-xxs-font-size: 0.7em;
        line-height: 0;
    }
    .recommendationDescriptionAndCircleChart {
        justify-content: space-between;
        --inv-grid-gutter-y: 0;
        align-items: center;
        margin-top: 1.4em;
    }
    .equityChartContainer {
        --inv-text-xxs-font-size: 0.7em;
        line-height: 0;
        align-items: center;
    }
    .equityChartTextContainer {
        padding-right: inherit;
    }
    .recommendationTableContainer {
        --inv-grid-gutter-y: 0;
        padding: 0 0.8em 0.7em;
        margin: 1.2em 0 0;
    }
    .recommendationBottomInfoRow {
        --inv-text-xxs-font-size: 0.7em;
        --inv-text-xxs-font-weight: 400;
        --inv-text-xs-font-size: 0.8em;
        --inv-text-xs-font-weight: 700;
        justify-content: center;
    }

}
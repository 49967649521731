.container {
    display: none;
}

.titleContainer {
    display: none;
}

.nextApportationContainer {
    --inv-text-xxs-font-size: 0.8em;
    --inv-text-l-font-size: 1.8em;
    --inv-text-xs-font-size: 1em;
}

.accumulatedContainer {
    --inv-text-xxs-font-size: 0.6em;
    --inv-text-l-font-size: 0.8em;
    --inv-text-xs-font-size: 0.7em;
}

.contentHeaderContainer {
    padding: 3.2em 1.8em;
}

.smallHeaderTextStructures {
    --inv-text-xxs-font-size: 0.6em;
    --inv-text-l-font-size: 0.8em;
    --inv-text-xs-font-size: 0.7em;
}

.infoHeaderContainer {
    justify-content: space-between;
    align-items: center;
    --solid-divider-color: var(--accent-color-1-light);
}

.apportationsContainer {
    --inv-grid-gutter-x: 0.8em;
    --inv-text-l-font-size: 0.8em;
    justify-content: space-between;
}

.apportationsContainer + * {
    margin-top: 0.8em;
}

.alertContainer{
    position: absolute;
    top: 7em;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
}

.alertContainer > *:not(:last-child) {
    margin-bottom: 1.2em;
}

@media (min-width: 576px) {
    .titleWithSubtitleContainer {
        margin-bottom: 3.2em;
    }
    .titleWithSubtitleContainer > *:first-child {
        margin-bottom: 0.4em;
    }
    .contentCol10 > * {
        margin-bottom: 6em;
    }
    .contentCol10 > *:last-child {
        margin-bottom: 0;
    }
    .contentContainer {
        border-radius: 1.2em;
        max-width: 72em;
        padding: 8em 3.9em 3.2em;
        position: relative;
        z-index: 1;
        background: #fff;
        margin: 0 auto;
    }
    .contentContainer > *:nth-child(3) {
        margin-bottom: 6em;
        margin-top: 3em;
    }
    .container {
        display: initial;
        top: 42em;
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
    }
    .apportationsContainer {
        --inv-text-l-font-size: inherit;
        --inv-grid-gutter-x: 3.2em;
    }
    .whiteContainer {
        width: 100vw;
        height: calc(100% + 4em);
        background: #fff;
        position: absolute;
        z-index: 0;
        margin-top: -11em;
    }
    .apportationsContainer + * {
        margin-top: 1.5em;
    }
    .titleContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 2.8em;
    }
    .nextApportationContainer {
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 3.95em;
        --inv-text-xs-font-size: 1.95em;
    }
    .accumulatedContainer {
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 3.95em;
        --inv-text-xs-font-size: 1.95em;
    }
    .smallHeaderTextStructures {
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 1.8em;
        --inv-text-xs-font-size: 1em;
    }
    .infoHeaderContainer {
        justify-content: center;
        --inv-grid-gutter-x: 3em;
    }

    .headerContainer {
        background: #fff;
        padding: 1.6em 3.2em;
        max-width: 73.15em;
        margin: 2.775em auto 0;
        border-radius: 0.8em;
        border: 0.05em solid var(--text-color-10);
        box-shadow: 0 0.4em 0.4em 0.05em rgba(170, 186, 202, 0.2);
        position: relative;
        z-index: 2;
    }

    .infoHeaderSmallQuantities{
        --inv-grid-gutter-x: 2em;
    }
    .goalContainer {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    .goalContainer.show {
        opacity: 1;
    }
    .goalContainer.hide {
        opacity: 0;
    }
}

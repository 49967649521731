.marginQ1Element1 {
    margin: 3.75em 0 1.6em;
}

.marginQ2Element1 {
    margin: 1.725em 0 1.6em;
}

.marginQ2Element2 {
    margin: 0 0 1.6em;
}

.marginQ2Element3 {
    margin: 0 0 1.6em;
}

.marginQ3Element1 {
    margin: 3.275em 0 1.6em;
}

@media (min-width: 576px) {
    .marginQ1Element1 {
        margin: 4em 0;
    }

    .marginQ2Element1 {
        margin: 4em 0;
    }

    .marginQ2Element3 {
        margin: 0 0 4em;
    }
    .marginQ2Element2Last {
        margin: 0 0 4em;
    }

    .marginQ3Element1 {
        margin: 4em 0;
    }

    .container {
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}

.container{
    background: #fff;
    padding: 1.6em 0;
    margin: 1.2em 0 0;
}
.profileDescriptionContainer{
    padding: 0 0.8em;
    justify-content: center;
    align-items: center;
}

.circleChartContainer{
    margin-top: 1.6em;
}

.profileDescriptionContainer > .circleChartContainer{
    display: none;
}

.sliderContainer{
    justify-content: center;
    --inv-text-xxs-font-size: 0.7em;
    margin-top: 4em;
}
.topCircleChartContainer{
    justify-content: center;
}

.divider{
    display: none;
}

.buttonsContainer{
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    --inv-grid-gutter-x: 0;
    padding: 0 0.8em;
    --inv-grid-gutter-y: 1.6em;
}

@media (min-width: 576px) {
    .buttonsContainer{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1.2em 1.6em 0;
    }
    .divider{
        display: flex;
        margin-top: 2.4em
    }
    .circleChartContainer{
        margin-top: 0;
    }
    .topCircleChartContainer{
        display: none;
    }
    .sliderContainer{
        margin-top: 8em;
    }
    .profileDescriptionContainer{
        padding: 0;

        --inv-grid-gutter-x: 4em;
    }

    .profileDescriptionContainer > .circleChartContainer{
        display: initial;
    }
    .container{
        max-width: 64.5em;
        border-radius: 1.6em;
        margin: 6em auto 16em;
        width: 100%;
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
        padding: 2.4em 0 1.2em;
    }
}
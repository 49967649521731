.container {
    --inv-text-m-font-size: 0.8em;
    --inv-text-l-font-size: 1em;
    padding: 0.4em 0.4em 1.6em;
}
.taxationOperationContainer {
    justify-content: center;
    --inv-text-l-font-size: 0.8em;
    --inv-text-xxs-font-size: 0.4em;
    --inv-grid-gutter-xs: 0.7em;
    align-items: flex-end;
    line-height: 0;
    --inv-grid-gutter-x: 0.4em;
    --inv-grid-gutter-y: 0.4em;
}
.adviceRow{
    justify-content: center;
    --inv-grid-gutter-x:0;
    margin: 1.6em 0.4em 0;
    --inv-advice-background: rgba(255, 242, 227, 0.60);
    --inv-advice-border-color: #F5931F;
}
.container > inv-grid-row:not(:first-child) {
    margin-top: 1.6em;
}
.titleContainer{
    --inv-text-l-font-weight: 700;
}
.titleContainer > *:first-child {
    margin-bottom: 0.8em;
    text-align: center;
}
.subtitleContainer {
    --inv-text-m-font-weight: 300;
    justify-content: center;
    text-align: center;
    line-height: 0;
}
.closeContainer{
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 0;
    width: 1.2em;
}
.closeRow{
--inv-grid-gutter-x: 0;
    justify-content: flex-end;
}
.closeIconContainer{
    margin: 0.4em 0.4em 1.6em 0;
    cursor: pointer;
}
@media (min-width: 576px) {
    .closeIconContainer{
        margin: 2.2em 2.2em 0 0;
    }
    .closeRow{
        justify-content: center;
    }
    .closeContainer{
        width: 2.2em;
        position: absolute;
        top: 0;
        right: 0;
    }
    .adviceRow{
        margin: inherit;
    }
    .container {
        --inv-text-m-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-l-font-size: 2.4em;
        padding: 5.2em 0;
    }
    .taxationOperationContainer {
        --inv-text-l-font-size: 1.6em;
        --inv-text-xxs-font-size: 0.8em;
        --inv-grid-gutter-xs: inherit;
        --inv-grid-gutter-x: 1.6em;
    }
    .container > inv-grid-row:not(:first-child) {
        margin-top: 3.2em;
    }
    .subtitleContainer {
        line-height: inherit;
        --inv-text-m-font-size: 1.4em;
    }
}

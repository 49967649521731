.container {
    --inv-grid-gutter-x: 0;
    --inv-grid-gutter-y: 0.8em;
    padding: 0 0.8em;
}

.selectorRow {
    width: 100%;
}

.newSelector {
    display: none;
}

.oldSelector {
    display: flex;
}

.selectorRowInnerContainer {
    width: 100%;
}

@media (min-width: 576px) {
    .newSelector {
        display: flex;
    }

    .oldSelector {
        display: none;
    }
    .container {
        --inv-grid-gutter-y: 1.6em;
        --inv-grid-gutter-x: 1.6em;
        padding: 0 1.6em;
    }
    .selectorRow {
        width: inherit;
    }
    .selectorRowInnerContainer {
        width: inherit;
    }
}

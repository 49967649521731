button{
    margin: 0;
    padding: 0;
    border: none;
    /* Resetting background and text color */
    background-color: transparent;
    color: inherit;
    /* Resetting font styles */
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    /* Resetting other styles */
    cursor: pointer;
    outline: none; /* Remove the outline when button is focused */
}

@media (min-width: 576px) {
    .main {
        font-size: inherit;
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-l-font-size: 1.8em;
        --inv-text-xs-font-size: 1em;
    }
}
.disclaimer{
    max-width: 62.2em;
    line-height: 100%;
    padding: 0 1.2em;
}

.disclaimer > p{
    color: var(--text-color-50);
    font-size: 0.5em;
    line-height: 130%;
}

.disclaimer > p:not(:last-child){
    margin-bottom: 2em;
}

@media (min-width: 576px) {
    .disclaimer{
        max-width: 62.2em;
        line-height: 100%;
    }

    .disclaimer > p{
        font-size: 0.7em;
        line-height: 130%;
    }

    .disclaimer > p:not(:last-child){
        margin-bottom: 2em;
    }
}
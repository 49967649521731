.container {
    background: #fff;
    max-width: 53.6em;
    width: 100%;
    margin: 3.2em auto 0;
    padding: 0;
    border-top-left-radius: 1.6em;
    border-top-right-radius: 1.6em;
}
@media (min-width: 576px) {
    .container {
        padding: 2.4em 3.7em;
        margin: 3.2em auto 6.4em;
        border-bottom-left-radius: 1.6em;
        border-bottom-right-radius: 1.6em;
    }
}